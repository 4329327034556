import React, { useEffect, useRef, useState } from 'react'
import Box from '../../../../components/box/Box'
import Typography from '../../../../components/typography/Typography'
import ArrowDown from '../../../../icons/ArrowDown'
import classes from '../DashboardTopBar.module.scss'
import theme from '../../../../theme/Theme'
import Avatar from './Avatar'
import MenuDropdown from '../../../../components/menu/Menu'
import { useTranslation } from 'next-i18next'
import DropDownItem from '../../../dropDown/DropDownItem'
import inboxIcon from '../../../../../../public/icons/toolbarProfile/inboxIcon.svg';
import logoutIcon from '../../../../../../public/icons/toolbarProfile/logoutIcon.svg';
import profileIcon from '../../../../../../public/icons/toolbarProfile/profileIcon.svg';
import settingIcon from '../../../../../../public/icons/toolbarProfile/settingIcon.svg';
import switchAccIcon from '../../../../../../public/icons/toolbarProfile/switchAccIcon.svg';
import verificationIcon from '../../../../../../public/icons/toolbarProfile/verificationIcon.svg';
import perksIcon from '../../../../../../public/icons/toolbarProfile/perks.svg';
import helpIcon from '../../../../../../public/icons/toolbarProfile/helpCenter.svg';
import { useRouter } from 'next/router'
import Divider from '../../../../components/divider/Divider'
import { useDispatch } from 'react-redux'
import { setAccountTypeBusiness, setAccountTypePersonal, updateCustomer } from '../../../../../redux/business/actions'
import { ACTIONS, Analytics } from '../../../../../settings/analytics'
import Business from '../../../../icons/Business'
import Skeleton from '@mui/material/Skeleton';
import Loader from '../../../loader/Loader'
import ArrowUp from '../../../../icons/ArrowUp'
import { BrowserLogger, BrowserLoggerOperation } from '../../../../../settings/browser-logger-v2'
import { MoEngageTrackEvent } from '../../../../../settings/moengage/moengage-service'
import moengage_events from '../../../../../settings/moengage/moengage-event'

const UserMenu = ({ customer, businessData, accountType, handleLogout }) => {
    const parentRef = useRef<HTMLDivElement>(null);
    const [open, setOpen] = useState(false);
    const { t } = useTranslation('toolbar-profile');
    const router = useRouter();
    const dispatch = useDispatch()
    const [bothAccount, setBothAccount] = useState(false);
    const [hasPersonal, setHasPersonal] = useState(false);
    const [hasBusiness, setHasBusiness] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [switchLabel, setSwitchLabel] = useState('');
    const [switching, setSwitching] = useState(false);

    const getFullName = () => {
        const { cus_firstname, cus_lastname } = customer || {};
        if (businessData && accountType === 'business' && businessData.name) {
            return businessData.name;
        } else if (cus_firstname && cus_lastname) {
            return `${cus_firstname || ""} ${cus_lastname || ""}`;
        }
        return 'RemitBee user';
    }

    const getInitials = () => {
        const { cus_firstname, cus_lastname } = customer || {};
        if (cus_firstname && cus_lastname) return `${cus_firstname[0]}${cus_lastname[0]}`;
        else return '';
    }

    const getAccount = () => ` ${(accountType === 'business' ? businessData?.business_unique_id : customer?.cus_unique_id) || 'N/A'}`;

    const renderListItem = (redirect?: string, icon?: string, text?: string, action?: any) => {
        return (
            <DropDownItem
                key={text}
                onClick={() => action ? action() : router.push(redirect)}
                listStyle={{ padding: 0 }}
            >
                <Box id={`user-menu-${text}`} className={classes['rb-userMenu-list-item-container']}>
                    <Box className={classes['rb-userMenu-list-item']}>
                        <img src={icon} alt={text} />
                        <Typography variant='label2' color={theme.palette.primary.navy}>{text}</Typography>
                    </Box>
                </Box>
            </DropDownItem>
        )
    }

    const getBusinessInfo = async () => {
        let label = '';

        setHasBusiness(customer.is_business_ac === '1');
        setHasPersonal(customer.is_personal_ac === '1');
        setBothAccount(customer.is_business_ac === '1' && customer.is_personal_ac === '1');

        if (customer.is_business_ac === '1') {
            label = accountType === 'personal' ? t('business') : t('personal');
        }
        if (customer.is_personal_ac === '1') {
            label = accountType === 'business' ? t('personal') : t('business');
        }
        if (customer.is_business_ac === '1' && customer.is_personal_ac === '1') {
            label = accountType === 'personal' ? t('switch-business') : t('switch-personal');
        }
        if (label === '') { // edge case to avoid showing empty value
            label = accountType === 'personal' ? t('business') : t('personal');
            // we should add logs here to track if it is going in this block of code
        }

        setSwitchLabel(label);
        setIsLoading(false);
    }

    const customerUpdate = async () => {
        setSwitching(true);

        BrowserLogger.log('Customer clicked switch account type button', {
            action_from: 'UserMenu',
            function_name: 'customerUpdate',
            operation: BrowserLoggerOperation.SWITCH_ACCOUNT_TYPE,
            current_account_type: accountType,
            has_business: hasBusiness,
            has_personal: hasPersonal,
        });

        if (accountType === 'personal' && !hasBusiness) {
            MoEngageTrackEvent(moengage_events.OPEN_BUSINESS_ACCOUNT);
            Analytics.track(ACTIONS.OPEN_BUSINESS_ACCOUNT, {});
            router.push('/business-account');
        } else if (accountType === 'business' && !hasPersonal) {
            Analytics.track(ACTIONS.OPEN_PERSONAL_ACCOUNT, {});
            router.push('/create-personal');
        } else if (accountType === 'personal' && bothAccount) {
            const { success, data } = await updateCustomer({ "last_active_ac": "BUSINESS" })
            if (success && data && data.updateCustomerPersonalInfo) {
                dispatch(setAccountTypeBusiness());
                Analytics.track(ACTIONS.SWITCH_TO_PERSONAL_ACCOUNT, {});
                BrowserLogger.setBusinessUniqueId(businessData?.business_unique_id);
                router.push('/business-account/dashboard')
            }
        } else if (accountType === 'business' && bothAccount) {
            const { success, data } = await updateCustomer({ "last_active_ac": "PERSONAL" })
            if (success && data && data.updateCustomerPersonalInfo) {
                Analytics.track(ACTIONS.SWITCH_TO_BUSINESS_ACCOUNT, {});
                dispatch(setAccountTypePersonal())
                BrowserLogger.setBusinessUniqueId();
                router.push('/dashboard')
            }
        }
    }

    useEffect(() => {
        getBusinessInfo()
    }, [customer])

    const listOptions = (
        <>
            <DropDownItem
                key={'profile-name'}
                onClick={() => { }}
                listStyle={{ padding: 0 }}
            >
                <Box className={classes['rb-userMenu-profile-container']}>
                    <Box className={classes['rb-userMenu-profile-detail']}>
                        <Typography variant='label1' weight='medium' color={theme.palette.primary.navy}>{getFullName()}</Typography>
                        <Typography variant='label3'>{`${t('account_number')}: `}<span id='account-number'>&nbsp;{getAccount()}</span></Typography>
                    </Box>
                </Box>
            </DropDownItem>
            <Box className={classes['rb-divider']}><Divider /></Box>
            <Box pt={2} pb={2} display='flex' flexDirection='column'>
                {renderListItem('/account-details', profileIcon, t('account_details'))}
                {renderListItem(accountType === 'personal' ? '/verification' : '/verification/business-levels', verificationIcon, t('verification_and_limit'))}
                {renderListItem('/inbox', inboxIcon, t('inbox'))}
                {renderListItem('/settings', settingIcon, t('settings'))}
                {renderListItem('/customer-help', helpIcon, 'Help centre')}
                {accountType === 'business' && renderListItem('/business-perks', perksIcon, t('business-perks'))}
            </Box>
            <Box className={classes['rb-divider']}><Divider /></Box>
            <Box pt={2} pb={2}>{renderListItem(null, accountType === 'personal' ? switchAccIcon : profileIcon, switchLabel, customerUpdate)}</Box>
            <Box className={classes['rb-divider']}><Divider /></Box>
            <Box pt={2}>{renderListItem(null, logoutIcon, t('logout'), handleLogout)}</Box>
        </>
    )

    const skeleton = (
        <>
            <DropDownItem
                key={'profile-name'}
                onClick={() => { }}
            >
                <Box className={classes['rb-userMenu-profile-container']}>
                    <Box className={classes['rb-userMenu-profile-detail']}>
                        <Typography variant='label1' weight='medium' color={theme.palette.primary.navy}>{getFullName()}</Typography>
                        <Typography variant='label3'>{`${t('account_number')}: `}<span id='account-number'>&nbsp;{getAccount()}</span></Typography>
                    </Box>
                </Box>
            </DropDownItem>
            <Box className={classes['rb-divider']}><Divider /></Box>
            <Skeleton width={235} height={48} className={classes['rb-topBar-skeleton']} />
            <Skeleton width={235} height={48} className={classes['rb-topBar-skeleton']} />
            <Skeleton width={235} height={48} className={classes['rb-topBar-skeleton']} />
            <Skeleton width={235} height={48} className={classes['rb-topBar-skeleton']} />

            {accountType === 'business' && <Skeleton width={235} height={48} className={classes['rb-topBar-skeleton']} />}

            <Box className={classes['rb-divider']}><Divider /></Box>
            <Box pt={2} pb={2}>
                <Skeleton width={235} height={48} className={classes['rb-topBar-skeleton']} />
            </Box>
            <Box className={classes['rb-divider']}><Divider /></Box>
            <Box pt={2} pb={2}>
                <Skeleton width={235} height={48} className={classes['rb-topBar-skeleton']} />
            </Box>


        </>
    )

    if (switching) return <Loader />

    return (
        <div ref={parentRef} style={{ position: 'relative' }}>
            <Box className={classes['rb-userMenu-dropdown']} onClick={() => setOpen(!open)}>
                <Box className={classes['rb-inner-container']}>
                    <Box className={classes['rb-name-container']}>
                        <Box style={{ width: 'fit-content' }} justifyContent='center'><Avatar initial={accountType !== 'business' ? getInitials() : <Business />} /></Box>
                        <Typography id='fullname' variant='label1' color={theme.palette.primary.navy}>{getFullName()}</Typography>
                        {open ? <ArrowUp width={24} height={24} color={'#1160F7'} /> : <ArrowDown width={24} height={24} color={'#1160F7'} />}
                    </Box>
                </Box>
            </Box>

            <MenuDropdown
                id='user-menu-dropdown'
                parentRef={parentRef}
                open={open}
                setOpen={setOpen}
                onClose={() => setOpen(false)}
                options={isLoading ? skeleton : listOptions}
                position={{ right: '0px' }}
            />
        </div>
    )
}

export default UserMenu