import React, { useEffect, useRef } from 'react';
import classes from './Loader.module.scss';
import Box from '../box/Box';

const Loader: React.FC<{
  backgroundBlur?: boolean;
}> = ({ backgroundBlur = false }) => {
  const container = useRef(null);

  useEffect(() => {
    let animation: any;

    const loadAnimation = async () => {
      try {
        const [lottie, animationData] = await Promise.all([
          import('lottie-web'),
          import('../../animations/Loader.json'),
        ]);
  
        animation = lottie?.default?.loadAnimation({
          container: container.current,
          animationData: animationData.default,
          renderer: 'svg',
          loop: true,
          autoplay: true,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
          },
        });
      } catch (error) {
        console.log(error)
      }

    };

    loadAnimation();

    return () => {
      if (animation) {
        animation.destroy();
      }
    };
  }, []);

  return (
    <Box
      className={`
       ${classes[`rb-spinner-overlay`]}
       ${backgroundBlur ? classes[`rb-spinner-overlay-blur`] : ''}
      `}
    >
      <Box className={classes['rb-spinner-container']}>
        <div ref={container} />
      </Box>
    </Box>
  );
};

export default Loader;
